import { queryAdAccountLabelListByUserId } from '@/api/adManagement.js';
export default {
//   data() {},
  computed: {
    adAccountLabelList() {
        // this.init()
      return this.$store.state.initData.adAccountLabelList;
    },
    isMultiAccountManage() {
      console.log(this.$route.path);
      return this.$route.path == '/ad/multiAccountManageIndex';
    },
  },
  created() {
    if (this.isMultiAccountManage) {
    }
  },
  methods: {}
};
