<template>
  <div>
    <el-dialog
      title="批量编辑"
      :visible="batchEditShow"
      width="80%"
      @close="close"
      @open="openInit"
      append-to-body
      :close-on-click-modal="false"
      v-dialog-drag
      class="editDialog"
    >
      <div
        class="editCon"
        v-loading="loading"
      >
        <div class="leftList">
          <choose-list
            :list="allList"
            @setSelectList="setSelectList"
            :defaultSelect="defaultSelect"
            v-if="selectList.length"
            @deleteAds="deleteAds"
            :currentLevel="level"
            :type="type"
            :mediaTypeMap="mediaTypeMap"
            :invaild_insterest="statusData ? statusData.invaild_insterest : []"
            :params="params"
            ref="chooseList"
          ></choose-list>
        </div>
        <div
          class="rightCon"
          v-if="allList.length && currentLevel == 'campaign'"
        >
          <ad-series
            :currentSelect="currentSelect"
            :baseData="[...allList]"
            v-if="batchEditShow"
            :updateList="updateAdSeriesData"
            ref="campaign"
            @setCampaignData="setCampaignData"
            v-loading="load"
            labelWidth="140px"
            @setBaseName="setBaseName"
            @setBidStrategy="(v) => setBidStrategy(v, 'campaign')"
            @setCbo="setCbo"
            @setTarget="setTarget"
            level="campaign"
            :copy="true"
            @setBudget="(key, val) => setBudget('campaign', key, val)"
            :params="params"
            :type="type"
          ></ad-series>
        </div>
        <div
          class="rightCon"
          v-if="adSetList.length && currentLevel == 'adset'"
        >
          <targeting
            :currentSelect="currentSelect"
            :baseData="[...adSetList]"
            :updateList="updateAdSetData"
            v-if="batchEditShow"
            @setData="setData"
            :pageList="pageList"
            ref="targeting"
            v-loading="load"
            :account="account"
            :copy="true"
            @setBaseName="setBaseName"
            :targetUserId="targetUserId"
            :statusDataItem.sync="statusData"
            @setBudget="(key, val) => setBudget('adset', key, val)"
            @setBid="(v) => setBudget('adset', 'bid_amount', v)"
            @setRoas="(v) => setBudget('adset', 'roas_average_floor', v)"
            :params="params"
            @setBidStrategy="(v, bidVal) => setBidStrategy(v, 'adset', bidVal)"
            :type="type"
          ></targeting>
        </div>
        <div
          class="rightCon"
          v-if="adList.length && currentLevel == 'ad'"
        >
          <creative
            :currentSelect="currentSelect"
            :baseData="[...adList]"
            v-if="batchEditShow"
            :updateList="updateAdData"
            :pageList="pageList"
            ref="creative"
            @setAdData="setAdData"
            v-loading="load"
            :account="account"
            @setBaseName="setBaseName"
            :show_mood="params ? params.show_mood : false"
            :targetUserId="targetUserId"
          ></creative>
          <div
            class="dis"
            v-if="params && params.show_mood"
          ></div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="close">取 消</el-button>
        <el-button
          @click="save"
          type="primary"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="确认要提交吗?"
      :visible.sync="creatForm"
      width="600px"
      append-to-body
    >
      <div>
        <el-form
          :model="upload"
          ref="ruleForm"
          label-width="200px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="是否立即上传到Facebook："
            prop="resource"
          >
            <el-switch
              v-model="upload.is_upload"
              :active-value="true"
              :inactive-value="false"
              @change="switchChange"
            >
            </el-switch>
          </el-form-item>
          <!-- <el-form-item label="上传之后广告的投放状态：" prop="name">
                        <el-switch v-model="params.campaign.status"  active-value="ACTIVE" inactive-value="PAUSED"> </el-switch>
                    </el-form-item> -->
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="creatForm = false">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          id="editCopyEvent"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import chooseList from './chooseList';
import targeting from '../../batchEdit/adset/targeting';
import creative from '../../batchEdit/ads/creative';
import adSeries from '../../batchEdit/campaign/adSeries';
import { draftForUpdate, ecFetch, createDraft, updateDraft } from '@/api/adManagement';
import { accountPages } from '@/api/creatAd';
import { domainReg } from '@/utils/utils';
import { mapState, mapGetters } from 'vuex';
import tools from '../../../createAd/components/tools';
export default {
  components: { chooseList, targeting, creative, adSeries },
  props: ['batchEditShow', 'list', 'initCreatAdData', 'level', 'type', 'params'],
  mixins: [tools],
  data() {
    return {
      selectList: [], //选择的广告组
      currentSelect: [], //选中的广告组
      baseData: [], //所有选择的广告的基本信息
      upadteData: [], //要保存的所有的广告组的信息
      pageList: [], //账户主页
      adSetList: [],
      adList: [],
      parentSelectList: [],
      currentLevel: this.level,
      updateAdSetData: [],
      updateAdData: [],
      flag: true,
      saveMsgList: [],
      resultStatus: [],
      load: false,
      timer: null,
      allList: [],
      loading: false,
      creatForm: false,
      updateAdSeriesData: [],
      upload: {
        is_upload: false,
      },
      statusData: {},
    };
  },
  created() {
    // if(this.level == 'ad'){
    //     this.getAccountPage()
    // }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('num', ['selectNum']),
    defaultSelect() {
      // //console.log(this.currentSelect);
      if (this.currentSelect.length) {
        return this.currentSelect;
      }
      if (this.level == 'ad') {
        let list = JSON.parse(JSON.stringify(this.adList)).filter((v) => v.creative.media_type !== 'asset_feed');
        let list1 = JSON.parse(JSON.stringify(this.adList)).filter((v) => v.creative.media_type == 'asset_feed');
        let lists = list.length ? list : list1;
        this.currentSelect =
          this.type == 'draft' ? lists.filter((m) => m.can_edit).map((v) => v.id) : lists.map((v) => v.id);
        return this.type == 'draft' ? lists.filter((m) => m.can_edit).map((v) => v.id) : lists.map((v) => v.id);
      } else if (this.level == 'adset') {
        let list = [];
        if (this.type == 'draft') {
          list = this.adSetList.filter((m) => m.can_edit).map((v) => v.id);
        } else {
          list = this.adSetList.map((v) => v.id);
        }
        this.currentSelect = [...list];
        return [...list];
      } else {
        let list = [];
        if (this.type == 'draft') {
          list = this.allList.filter((m) => m.can_edit).map((v) => v.id);
        } else {
          list = this.allList.map((v) => v.id);
        }
        this.currentSelect = [...list];
        return [...list];
      }
    },
    account() {
      return this.params && this.params.target_account_id ? this.params.target_account_id : this.accountId;
    },
    targetUserId() {
      console.log(this.params);
      return this.params && this.params.target_third_user_id ? this.params.target_third_user_id : this.selectNum;
    },
    // 多账号 currentNum 当前个号
    currentRowNum() {
      return this.params && this.params.source_third_user_id ? this.params.source_third_user_id : this.selectNum;
    },
    accountId() {
      return this.params && this.params.source_account_id
        ? this.params.source_account_id
        : this.$store.getters.accountId;
    },
    mediaTypeMap() {
      let obj = {};
      this.updateAdData.forEach((item) => {
        obj[item.id] = {
          media_type: item.creative.media_type,
          videoStatus: item.creative.videoStatus,
          thumbUrl: item.creative.thumbUrl,
          videoId: item.creative.videoId,
        };
      });
      return obj;
    },
  },
  watch: {
    initCreatAdData: {
      handler() {
        this.init = this.initCreatAdData;
      },
    },
    creatForm(val) {
      if (val) {
        this.upload.is_upload = localStorage.getItem('copy-upload-fb') == 1 ? true : false;
      }
    },
  },
  methods: {
    switchChange(val) {
      // 记录用户操作
      if (val) {
        localStorage.setItem('copy-upload-fb', 1);
      } else {
        localStorage.setItem('copy-upload-fb', 0);
      }
    },
    // 名称改变的时候改变左侧树状的名称
    setBaseName(level, id, name, pid) {
      // //console.log(level,name,id);
      // return false;
      if (level == 'campaign') {
        this.allList.map((campaign, index) => {
          if (id == campaign.id) {
            this.allList[index].name = name;
          }
        });
        this.updateAdSeriesData.map((campaign, index) => {
          if (id == campaign.id) {
            this.allList[index].name = name;
          }
        });
      }
      if (level == 'adset') {
        this.allList.map((campaign, index) => {
          campaign.list.map((adset, i) => {
            if (adset.id == id) {
              adset.name = name;
            }
          });
        });
        this.updateAdSetData.map((adset, index) => {
          if (adset.id == id) {
            adset.name = name;
          }
        });
      }
      if (level == 'ad') {
        // //console.log(this.allList);
        this.allList.map((campaign, index) => {
          campaign.list.map((adset, i) => {
            if (!adset.list) {
              return false;
            }
            adset.list.map((ad, ind) => {
              // //console.log(ad);
              if (ad.id == id) {
                // //console.log(ad);
                ad.name = name;
              }
            });
          });
        });
        this.updateAdData.map((v) => {
          if (v.id == id) {
            v.name = name;
          }
        });
      }
      //console.log(this.updateAdData);
      this.allList = JSON.parse(JSON.stringify(this.allList));
      //console.log(this.allList);
    },
    // 修改预算值
    setBudget(level, key, val) {
      console.log(level, key, val, '3333333333333333333333');
      if (level == 'campaign') {
        this.allList.map((campaign, index) => {
          if (this.currentSelect.includes(campaign.id)) {
            campaign[key] = val;
          }
        });
      }
      if (level == 'adset') {
        this.allList.map((campaign, index) => {
          campaign.list.map((adset) => {
            if (this.currentSelect.includes(adset.id)) {
              adset[key] = val;
            }
          });
        });
      }
      this.allList = JSON.parse(JSON.stringify(this.allList));
    },
    // 从系列层级设置出价方式#需要改变每个广告组的出价方式
    setBidStrategy(v, level, bidVal) {
      if (level == 'adset') {
        this.allList.map((campaign, index) => {
          campaign.list.map((adset, i) => {
            if (this.currentSelect.includes(adset.id)) {
              adset.bid_strategy = v;
              if (v == 'LOWEST_COST_WITH_MIN_ROAS') {
                adset.roas_average_floor = bidVal || 0;
              } else {
                adset.bid_amount = bidVal || 0;
              }
            }
          });

          this.updateAdSetData.map((adset, index) => {
            if (this.currentSelect.includes(adset.id)) {
              adset.bid_strategy = v;
              if (v == 'LOWEST_COST_WITH_MIN_ROAS') {
                adset.roas_average_floor = bidVal || 0;
              } else {
                adset.bid_amount = bidVal || 0;
              }
            }
          });
        });
      } else {
        this.allList.map((campaign, index) => {
          if (this.currentSelect.includes(campaign.id)) {
            campaign.list.map((adset, i) => {
              adset.bid_strategy = v;
              if (v == 'LOWEST_COST_WITH_MIN_ROAS') {
                adset.roas_average_floor = bidVal || 0;
              } else {
                adset.bid_amount = bidVal || 0;
              }
            });
            this.updateAdSetData.map((adset, index) => {
              console.log(adset.parentId, 'adset.campaign_id', campaign.id);
              if (adset.parentId == campaign.id) {
                adset.bid_strategy = v;
                if (v == 'LOWEST_COST_WITH_MIN_ROAS') {
                  adset.roas_average_floor = bidVal || 0;
                } else {
                  adset.bid_amount = bidVal || 0;
                }
              }
            });
          }
        });
      }
      this.allList = JSON.parse(JSON.stringify(this.allList));
    },
    // 设置预算优化方式
    setCbo(v) {
      console.log('object-is_cbo', v);
      this.updateAdSeriesData.map((campaign, index) => {
        if (this.currentSelect.includes(campaign.id)) {
          campaign.is_cbo = v;
          campaign.daily_budget = v ? 4000 : 0;
          campaign.lifetime_budget = 0;
        }
      });
      this.allList.map((campaign, index) => {
        if (this.currentSelect.includes(campaign.id)) {
          campaign.is_cbo = v;
          campaign.daily_budget = v ? 4000 : 0;
          campaign.lifetime_budget = 0;
          campaign.list.map((adset, i) => {
            adset.is_cbo = v;
            adset.lifetime_budget = 0;
            adset.daily_budget = v ? 0 : 4000;
            adset.bid_strategy = 'LOWEST_COST_WITHOUT_CAP';
            adset.bid_amount = 0;
          });
        }
      });
      this.updateAdSetData.map((adset, index) => {
        if (this.currentSelect.includes(adset.parentId)) {
          adset.is_cbo = v;
          adset.lifetime_budget = 0;
          adset.daily_budget = v ? 0 : 4000;
          adset.bid_strategy = 'LOWEST_COST_WITHOUT_CAP';
          adset.bid_amount = 0;
        }
      });
    },
    // 系列层级设置优化目标
    setTarget(v) {
      this.updateAdSeriesData.map((campaign, index) => {
        if (this.currentSelect.includes(campaign.id)) {
          campaign.optimization_goal = v;
        }
      });
      this.allList.map((campaign, index) => {
        if (this.currentSelect.includes(campaign.id)) {
          campaign.optimization_goal = v;
          campaign.list.map((adset, i) => {
            adset.optimization_goal = v;
            adset.attribution_spec = 'click_1';
          });
        }
      });
      this.updateAdSetData.map((adset, index) => {
        if (this.currentSelect.includes(adset.parentId)) {
          adset.optimization_goal = v;
          adset.attribution_spec = 'click_1';
        }
      });
    },
    // 获取账户主页
    async getAccountPage(v) {
      this.loadPages = true;
      await accountPages(
        {
          accountId: this.params && this.params.target_account_id ? this.params.target_account_id : this.accountId,
          force: v,
        },
        this.targetUserId,
      ).then((res) => {
        this.loadPages = false;
        let canUse = res.data.filter((v) => v.is_published);
        let notUse = res.data.filter((v) => !v.is_published);
        this.pageList = canUse.concat(notUse);
        if (v) {
          this.$message('更新成功');
        }
      });
    },
    close() {
      if (this.$refs['creative']) {
        this.$refs['creative'].cancleGetbatchVideoStatus = true;
      }
      this.$emit('update:batchEditShow', false);
      this.selectList = [];
      this.baseData = [];
      this.adSetList = [];
      this.adList = [];
      this.allList = [];
      this.currentLevel = '';
      this.currentSelect = [];
    },
    openInit() {
      this.currentLevel = this.level;
      this.selectList = JSON.parse(JSON.stringify(this.list));
      this.getAccountPage();
      // this.getList();
      // 从草稿箱进入
      if (this.type == 'draft') {
        this.getDraftMsg();
      } else {
        this.getMsg();
      }
    },
    // 删除树状数据中的一个
    deleteAds(data) {
      // //console.log(data);
      if (data.level == 'campaign') {
        this.allList.map((campaign, index) => {
          if (data.id == campaign.id) {
            this.allList.splice(index, 1);
          }
        });
        this.updateAdSeriesData.map((campaign, index) => {
          if (data.id == campaign.id) {
            this.updateAdSeriesData.splice(index, 1);
          }
        });
      }
      if (data.level == 'adset') {
        this.allList.map((campaign, index) => {
          if (data.parentId == campaign.id) {
            campaign.list.map((adset, i) => {
              if (data.id == adset.id) {
                this.allList[index].list.splice(i, 1);
              }
            });
          }
        });
        this.updateAdSetData.map((adset, index) => {
          if (data.id == adset.id) {
            this.updateAdSetData.splice(index, 1);
          }
        });
      }
      if (data.level == 'ad') {
        this.allList.map((campaign, index) => {
          campaign.list.map((adset, i) => {
            if (data.parentId == adset.id) {
              adset.list.map((ad, ind) => {
                if (ad.id == data.id) {
                  this.allList[index].list[i].list.splice(ind, 1);
                }
              });
            }
          });
        });
        this.updateAdData.map((ad, index) => {
          if (data.id == ad.id) {
            this.updateAdData.splice(index, 1);
          }
        });
      }
      this.allList = JSON.parse(JSON.stringify(this.allList));
      this.updateAdSetData = JSON.parse(JSON.stringify(this.updateAdSetData));
      this.updateAdData = JSON.parse(JSON.stringify(this.updateAdData));
      let list = [...this.currentSelect];
      if (list.indexOf(data.id) >= 0) {
        list.splice(list.indexOf(data.id), 1);
        this.setSelectList(list, data.level);
      }
      // let adsetList = [],adList = [];
      // this.allList.map(v=>{
      //     adsetList.concat(v.list);
      //     v.list.map(k=>{
      //         adList.concat(k.list)
      //     })
      // })
      // this.adSetList = adsetList;
      // this.adList = adList;
      // this.updateAdSetData = JSON.parse(JSON.stringify(adsetList))
      // this.updateAdSeriesData = JSON.parse(JSON.stringify(this.allList))
      // this.updateAdData = JSON.parse(JSON.stringify(adList))
    },
    // 处理数据
    setBaseMsg(res) {
      this.loading = false;
      if (res.code !== 0) {
        this.adSetList = [];
        this.adList = [];
        return false;
      }
      let adList = [],
        adSetList = [];
      let list = JSON.parse(JSON.stringify(res.data));
      this.allList = list;
      list.map((v) => {
        // 处理受众
        v.id = v.id || v.draft_id;
        v.upload_status = 'UPLOADED';
        v.level = 'campaign';
        if (v.adsets && v.adsets.length) {
          v.adsets.map((k) => {
            k.campaignObjective = v.objective;
            k.level = 'adset';
            k.is_cbo = v.is_cbo;
            k.bid_strategy = k.bid_strategy || v.bid_strategy;
            k.id = k.id || k.draft_id;
            k.parentId = v.id;
            k.start_date = k.start_time;
            k.end_date = k.end_time;
            k.business = v.businessType;
            k.smart_promotion_type = v.smart_promotion_type;
            k.targeting = [{ ...k.targeting }];
            if (k.targeting[0].exclusions) {
              k.targeting[0].exclusions = [k.targeting[0].exclusions];
            } else {
              k.targeting[0].exclusions = [];
            }
            if (k.targeting[0].flexible_spec) {
              k.targeting[0].flexible_spec = k.targeting[0].flexible_spec.length ? k.targeting[0].flexible_spec : [];
            }
            if (k.targeting[0].genders && k.targeting[0].genders.length == 2) {
              k.targeting[0].genders = 'all';
            } else {
              k.targeting[0].genders = k.targeting[0].genders ? k.targeting[0].genders[0] : '';
            }
            // 处理进阶赋能型受众
            k.targeting[0].targeting_automation = k.targeting[0].targeting_automation || {
              advantage_audience: 0,
            };
            if (k.ads && k.ads.length) {
              k.ads.map((m) => {
                m.id = m.id || m.draft_id;
                m.parentId = k.id;
                m.objective = v.objective;
                m.level = 'ad';
              });
              k.list = JSON.parse(JSON.stringify(k.ads));
              adList = adList.concat(k.ads);
            }
            k.daily_budget_campaign = v.daily_budget;
            k.lifetime_budget_campaign = v.lifetime_budget;
          });
          v.list = JSON.parse(JSON.stringify(v.adsets));
          adSetList = adSetList.concat(v.adsets);
        }
      });
      this.adSetList = adSetList;
      this.adList = adList;
      this.updateAdSetData = JSON.parse(JSON.stringify(adSetList));
      this.updateAdSeriesData = JSON.parse(JSON.stringify(this.allList));
      this.updateAdData = JSON.parse(JSON.stringify(adList));
    },
    // 获取草稿箱数据
    getDraftMsg() {
      this.loading = true;
      draftForUpdate({ draftCampaignIds: this.list.map((v) => v.id).join(',') }).then((res) => {
        this.$hideLoading();
        this.setBaseMsg(res);
      });
    },
    // 获取广告数据
    getMsg() {
      this.loading = true;
      ecFetch(this.params, this.currentRowNum).then((res) => {
        this.$hideLoading();
        this.setBaseMsg(res);
      });
    },
    setSelectList(v, level) {
      console.log(v);
      this.timer = null;
      this.load = true;
      this.currentSelect = [...v];
      this.currentLevel = level;
      setTimeout(this.interval, 500);
      // this.$store.commit('initData/setCurrentSelect',v)
    },
    interval() {
      this.load = false;
    },
    setData(v) {
      this.updateAdSetData = JSON.parse(JSON.stringify(v));
    },
    setAdData(v) {
      this.updateAdData = JSON.parse(JSON.stringify(v));
    },
    setCampaignData(v) {
      this.updateAdSeriesData = JSON.parse(JSON.stringify(v));
    },
    saveAdset() {
      // //console.log(this.$refs.targeting.baseData);
      let list = JSON.parse(JSON.stringify(this.$refs.targeting.listData));
      list.map((v) => {
        v.targeting[0].genders = v.targeting[0].genders === 'all' ? ['M', 'F'] : [v.targeting[0].genders];
        v.targeting[0].exclusions =
          v.targeting[0].exclusions && v.targeting[0].exclusions.length ? v.targeting[0].exclusions[0] : null;
      });
      this.$showLoading();
      batchUpdateAdSet({ adsetForUpdate: list }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: res.comment,
          });
          this.$emit('get-list');
          this.close();
        }
        this.$hideLoading();
      });
    },
    saveAdCreative() {
      let list = JSON.parse(JSON.stringify(this.$refs.creative.listData));
      if (list.some((v) => v.creative.show_url && !/^(\w+\.)+\w+$/.test(v.creative.show_url))) {
        this.$message({
          type: 'warning',
          message: '请输入正确的显示链接',
        });
        return false;
      }
      let flag = true;
      list.map((v) => {
        if (v.creative.media_type == 'muti') {
          v.creative.mutiImgs.map((key, index) => {
            delete key.name;
            delete key.fixed;
            if (!key.image) {
              v.creative.mutiImgs.splice(index, 1);
            }
            if (v.creative.mutiImgs.length < 3) {
              flag = false;
            }
          });
        }
      });
      if (!flag) {
        this.$message({
          type: 'warning',
          message: '轮播图请至少上传三张图片',
        });
        return false;
      }
      this.$showLoading();
      batchUpdateAd({ adForBatches: list }).then((res) => {
        // //console.log(res);
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: res.comment,
          });
          this.$emit('get-list');

          this.close();
        }
        this.$hideLoading();
      });
    },
    // 处理受众
    editFlex(list) {
      if (!list) return null;
      // //console.log(list);
      list.map((v, i) => {
        let flag = false;
        for (let key in v) {
          if (v[key] && v[key].length) {
            flag = true;
          }
        }
        if (!flag) {
          list.splice(i, 1);
        }
      });
      return list.length ? list : null;
    },
    saveList() {
      let adsetList = this.updateAdSetData.length
        ? JSON.parse(JSON.stringify(this.updateAdSetData))
        : JSON.parse(JSON.stringify(this.adSetList));
      let baseList = this.updateAdSeriesData
        ? JSON.parse(JSON.stringify(this.updateAdSeriesData))
        : JSON.parse(JSON.stringify(this.allList));
      if (
        adsetList.some(
          (v) =>
            v.targeting[0].excluded_geo_locations_places &&
            v.targeting[0].excluded_geo_locations_places.length &&
            !v.targeting[0].excluded_geo_locations_place_radius,
        )
      ) {
        this.$message({
          type: 'warning',
          message: '请填写排除城镇公里数',
        });
        return false;
      }
      if (
        adsetList.some(
          (v) =>
            !(
              (v.targeting[0].geo_locations && v.targeting[0].geo_locations.length) ||
              (v.targeting[0].geo_locations_region && v.targeting[0].geo_locations_region.length) ||
              (v.targeting[0].geo_locations_city && v.targeting[0].geo_locations_city.length)
            ),
        )
      ) {
        this.$message({
          type: 'warning',
          message: '请选择受众地区',
        });
        return false;
      }
      adsetList.map((v) => {
        v.start_time = v.start_date;
        v.end_time = v.end_date;
        v.targeting[0].genders =
          v.targeting[0].genders === 'all' ? ['M', 'F'] : v.targeting[0].genders ? [v.targeting[0].genders] : null;
        v.targeting[0].exclusions =
          v.targeting[0].exclusions && v.targeting[0].exclusions.length ? v.targeting[0].exclusions[0] : null;
      });
      let adList = this.updateAdData.length
        ? JSON.parse(JSON.stringify(this.updateAdData))
        : JSON.parse(JSON.stringify(this.adList));
      //console.log(adList);
      if (adList.some((v) => v.creative.show_url && !/^(\w+\.)+\w+$/.test(v.creative.show_url))) {
        this.$message({
          type: 'warning',
          message: '请输入正确的显示链接',
        });
        return false;
      }
      // if(adList.some(v=> !v.conversion_domain)){
      //     this.$message({
      //         type:'warning',
      //         message:'请输入正确的网域'
      //     })
      //     return false;
      // }
      let flag = true;
      let assetFlag = true,
        videoFlag = true;
      adList.forEach((v) => {
        if (v.creative.media_type == 'muti') {
          v.creative.mutiImgs.forEach((key, index) => {
            delete key.name;
            delete key.fixed;
            if (!key.image) {
              v.creative.mutiImgs.splice(index, 1);
            }
            if (v.creative.mutiImgs.length < 2) {
              flag = false;
            }
          });
        }
        if (v.creative.media_type == 'asset_feed') {
          let fbAssetFeedSpec = v.creative.fbAssetFeedSpec;
          fbAssetFeedSpec.actionTypes = fbAssetFeedSpec.actionTypes.filter((v) => v);
          fbAssetFeedSpec.linkUrls = fbAssetFeedSpec.linkUrls.filter((v) => v);
          fbAssetFeedSpec.imgList = fbAssetFeedSpec.imgList || [];
          fbAssetFeedSpec.videos = fbAssetFeedSpec.videos || [];
          let list = fbAssetFeedSpec.imgList.concat(fbAssetFeedSpec.videos);
          if (
            !fbAssetFeedSpec.mAdFormats.length ||
            !list.length ||
            !fbAssetFeedSpec.titles.length ||
            !fbAssetFeedSpec.mDescriptions.length ||
            !fbAssetFeedSpec.mBodies.length ||
            !fbAssetFeedSpec.actionTypes.length ||
            !fbAssetFeedSpec.linkUrls.length
          ) {
            assetFlag = false;
          }
          if (!this.previewBefore(fbAssetFeedSpec)) {
            return false;
          }
        }
        if (
          (v.creative.media_type == 'video' && !v.creative.thumbUrl) ||
          (v.creative.media_type == 'video' && v.creative.videoStatus == 'processing')
        ) {
          videoFlag = false;
        }
      });
      if (!flag) {
        this.$message({
          type: 'warning',
          message: '轮播图请至少上传2张图片',
        });
        return false;
      }
      if (!assetFlag) {
        this.$message({
          type: 'warning',
          message: '动态素材不完整',
        });
        return false;
      }
      if (!videoFlag) {
        this.$message({
          type: 'warning',
          message: '请检查视频封面是否上传',
        });
        return false;
      }
      // 给每个广告组加上ads字段
      adsetList.map((v) => {
        v.ads = [];
        delete v.list;
        adList.map((k) => {
          if (v.id == k.parentId) {
            v.ads.push(k);
          }
        });
      });
      // 把广告组放进广告系列列表
      baseList.map((v) => {
        v.adsets = [];
        delete v.list;
        adsetList.map((m) => {
          if (v.id == m.parentId) {
            m.targeting = m.targeting[0];
            v.adsets.push(m);
          }
        });
      });
      this.saveMsgList = JSON.parse(JSON.stringify(adsetList));
      if (this.type == 'draft') {
        this.saveDraft(baseList);
      } else {
        this.saveMethods(baseList);
      }
    },
    saveMethods(list) {
      this.$showLoading();
      let params = {
        target_account_id: this.params.target_account_id,
        target_third_user_id: this.params.target_third_user_id,
        user_id: this.userInfo.data.id,
        campaigns: [...list],
        is_upload: this.upload.is_upload,
        sale: this.params.replace_option.sale,
        site: this.params.replace_option.site,
      };
      createDraft(params, this.currentRowNum).then((res) => {
        this.$hideLoading();
        // //console.log(res);
        if (res.code == 0) {
          this.$emit('get-list');
          this.$message({
            type: 'success',
            message: this.upload.is_upload ? '提交成功，请稍后去草稿箱查看上传结果' : '成功保存到草稿箱',
          });
          (this.creatForm = false), this.close();
        }
      });
    },
    saveDraft(list) {
      this.$showLoading();
      let params = { campaigns: [...list], is_upload: this.upload.is_upload };
      updateDraft(params).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.$emit('get-list');
          (this.creatForm = false), this.close();
          this.$message({
            type: 'success',
            message: this.upload.is_upload ? '提交成功，请稍后去草稿箱查看上传结果' : '成功保存到草稿箱',
          });
        }
      });
    },
    // 失败消息提示
    messageTost(list) {
      if (!list.length) {
        this.$message({
          type: 'success',
          message: '修改成功',
        });
        this.$emit('get-list');
        this.close();
        this.flag = true;
        return false;
      } else {
        this.$message({
          type: 'error',
          message: '存在保存失败的选项，请点击重试，或者忽略',
        });
        this.flag = false;
        this.resultStatus = list;
      }

      // list.map(v=>{
      //     // if(v.obj_status == 'success'){
      //     //     //console.log(v);
      //     //     this.$notify({
      //     //         title: '成功',
      //     //         message:   `${v.level == 'ADSET'?'广告组':v.level == 'AD'?'广告':''}: ${v.obj_name} 保存成功`,
      //     //         type: 'success',
      //     //         position: 'bottom-right'
      //     //     });
      //     // }else{
      //     //     this.$notify.error({
      //     //         title: `${v.level == 'ADSET'?'广告组':v.level == 'AD'?'广告':''}: ${v.obj_name} 保存失败`,
      //     //         message: v.result_msg,
      //     //         duration: 0,
      //     //         position: 'bottom-right'
      //     //     });
      //     // }
      // })
    },
    tryAgain() {
      this.resultStatus.map((v) => {
        if (v.obj_level == 'ADSET') {
          this.saveMsgList.map((k) => {
            if (v.obj_status == 'success' && k.id == v.obj_id) {
              k.changed = false;
            }
          });
        } else if (v.obj_level == 'AD') {
          this.saveMsgList.map((k) => {
            k.ads.map((m) => {
              if (m.id == v.obj_id) {
                m.changed = false;
              }
            });
          });
        }
      });
      this.saveMethods(this.saveMsgList);
    },
    save() {
      if (
        this.$refs.targeting?.statusData?.invaild_insterest &&
        this.$refs.targeting.statusData.invaild_insterest.length > 0
      ) {
        this.$notify.error('存在无效的兴趣词，请删除后再提交');
        return;
      }
      if (this.$refs.campaign?.$refs.budget) {
        this.$refs.campaign.$refs.budget.$refs.budgetForm?.validate((v) => {});
      }
      if (this.$refs.targeting?.$refs.budget) {
        this.$refs.targeting.$refs.budget.$refs.budgetForm?.validate((v) => {});
      }
      let { bidMap, budgetMap } = this.$refs.chooseList.tipsMap || { bidMap: {}, budgetMap: {} };
      if (Object.keys(bidMap).length) {
        this.$message({
          type: 'warning',
          message: '请检查广告组出价金额是否填写',
        });
        return false;
      }
      if (Object.keys(budgetMap).length) {
        this.$message({
          type: 'warning',
          message: '请检查预算金额是否填写',
        });
        return false;
      }
      this.creatForm = true;
    },
    sure(is_upload) {
      // if(this.level == 'adset'){
      //     this.saveAdset()
      // }
      // if(this.level == 'ad'){
      //     this.saveAdCreative()
      // }
      this.is_upload = is_upload;
      this.saveList();
    },
  },
};
</script>
<style lang="scss">
.editDialog {
  .el-dialog__body,
  .el-dialog__footer {
    background: #fff;
  }
}
</style>
<style lang="scss" scope>
.editCon {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-height: 700px;
  overflow-y: auto;
  .leftList {
    flex: 0 0 340px;
    overflow: hidden;
    max-height: 700px;
    overflow-y: auto;
  }
  .rightCon {
    flex: 1;
    padding-left: 20px;
    max-height: 700px;
    overflow-y: auto;
    padding-right: 20px;
    position: relative;
    .dis {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 700px;
      background: rgba($color: #fff, $alpha: 0.6);
    }
  }
}
</style>
