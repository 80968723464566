<template>
    <div>
        <component :is="which" :level="level" :visible="visible" :edit-rows="editRows" @close-drawer="closeDrawer" @refresh="refreshList"></component>
    </div>

</template>

<script>
    import CopyCampaign from "./components/campaign";
    import CopyAdset from "./components/adset";
    import CopyAd from "./components/ad";

    export default {
        name: "CopyDrawer",
        components: { CopyAdset, CopyCampaign,CopyAd},
        // props: [
        //     'selectedRowScopes',
        //     'level',
        //     'visible',
        // ],
        props:{
            visible:{
                type:Boolean,
                default:false
            },
            selectedRowScopes:{
                type:Array,
                default:(()=>[])
            },
            level:{
                type:String,
                default:''
            },
            editRows:{
              type:Array,
              default:(()=>[])
            }
        },
        computed:{
            which(){
                return 'copy-' + this.level
            }
        },
        data() {
            return {
                levels: ['campaign', 'adset', 'ad'],
            }
        },
        methods: {
            closeDrawer() {
                this.$emit('close-drawer')
            },
            refreshList(){
                this.$emit('reload-table')
            }
        },
    }
</script>

<style lang="scss">
.el-drawer__body{
    max-height: 100%;
    overflow-y: auto;
}
</style>