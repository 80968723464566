<template>
    <el-input v-model="url" @blur="blur" v-loading="loading"></el-input>
</template>

<script>
    import { promotionInfo } from '@/api/creatAd.js'
    export default {
        data() {
            return {
                url: '',
                accountId: this.$store.getters.accountId,
                loading: false
            }
        },
        methods: {
            blur() {
                if (this.url) {
                    this.loading = true
                    promotionInfo({url: this.url}).then(res=>{
                        this.loading = false
                        // console.log(res);
                        if(res.code == 0){
                            // this.checkLinkStatus = res.data;
                            this.$emit('linkInfo', res.data, this.url)
                        }else{
                            this.$emit('linkInfo', {sale:null,site:null}, this.url)
                        }
                    }).catch(() => {
                        this.loading = false
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>